import { ref, type Ref } from 'vue'

export function trueTimeout(
  callback: () => void,
  duration: number,
  tolerance: number = 100,
): Ref<number> {
  const desirableEnd = Date.now() + duration
  const res = ref(0)

  const wrapper = () => {
    const realDifference = desirableEnd - Date.now()
    if (realDifference > tolerance) {
      // too early -> reschedule
      res.value = setTimeout(wrapper, realDifference)
    } else {
      callback()
    }
  }
  res.value = setTimeout(wrapper, duration)

  return res
}

export function trueInterval(
  callback: () => void | Promise<void>,
  duration: number,
  tolerance: number = 100,
): Ref<number> {
  let lastTick = Date.now()
  const res = ref(0)

  const wrapper = () => {
    if (Date.now() > lastTick - tolerance) {
      let cbr = callback()
      if (!(cbr instanceof Promise)) { cbr = Promise.resolve() } // для единообразия
      cbr.then(() => lastTick = Date.now())
    } else {
      // we're in incorrect situation -> prevent future false triggering, reschedule
      clearInterval(res.value)
      res.value = setInterval(wrapper, duration)
    }
  }
  res.value = setInterval(wrapper, duration)

  return res
}

export function trueSleep(timeout: number): Promise<void> {
  return new Promise((resolve) => {
    trueTimeout(resolve, timeout)
  })
}
