<script setup lang="ts">
import format from "date-fns/format"
import ru from "date-fns/locale/ru"
import parse from "date-fns/parse"
import setDateValues from "date-fns/set"
import { vMaska } from "maska"
import { watch, computed, ref, nextTick, reactive, useTemplateRef } from "vue"
import type { MaskaDetail } from "maska"
import type { Ref } from "vue"

type DateInputField = "day" | "month" | "year"

const props = withDefaults(
  defineProps<{
    modelValue: string
    inputFormat?: string
    divider?: string
    dayformat?: string
    monthFormat?: string
    yearFormat?: string
  }>(),
  {
    inputFormat: "dd.MM.yyyy",
    divider: ".",
    dayformat: "dd",
    monthFormat: "MM",
    yearFormat: "yyyy",
  }
)

const emit = defineEmits(["update:modelValue"])

const tokens = {
  Y: { pattern: /[1-2]/ },
  y: { pattern: /[0]/ },
  k: { pattern: /[0-9]/ },
  l: { pattern: /[9]/ },
  u: { pattern: /[8-9]/ },
  j: { pattern: /[0-2]/ },
  M: { pattern: /[0, 1]/ },
  m: { pattern: /[0-2]/ },
  D: { pattern: /[0-3]/ },
  d: { pattern: /[0, 1]/ },
  c: { pattern: /[1-9]/ },
  "#": { pattern: /\d/ },
}

const monthInput = useTemplateRef('monthInput')
const yearInput = useTemplateRef('yearInput')
const dayInput = useTemplateRef('dayInput')

const parsedValue = computed(() =>
  props.modelValue
    ? parse(props.modelValue, props.inputFormat, new Date())
    : null
)

const year: Ref<string | null> = ref(
  parsedValue.value
    ? format(parsedValue.value, props.yearFormat, { locale: ru })
    : null
)
const month: Ref<string | null> = ref(
  parsedValue.value
    ? format(parsedValue.value, props.monthFormat, { locale: ru })
    : null
)
const day: Ref<string | null> = ref(
  parsedValue.value
    ? format(parsedValue.value, props.dayformat, { locale: ru })
    : null
)

const isEmpty = (value: string | null) => value === "" || value === null

const hasEmptyFields = computed(
  () => isEmpty(day.value) || isEmpty(month.value) || isEmpty(year.value)
)

const allFieldsAreEmpty = computed(
  () => isEmpty(day.value) && isEmpty(month.value) && isEmpty(year.value)
)

const yearMask = reactive({
  mask: (value: string) => {
    const mask = ["Y", "y", "j", "#"]
    if (value.length > 0) {
      if (value[0] === "1") {
        mask[1] = "l"
        mask[2] = "u"
      }
    }
    if (value && value.split("").slice(0, 3).join("") === "198") {
      mask[3] = "l"
    }
    return mask.join("")
  },
  tokens,
})

const monthMask = reactive({
  mask: (value: string) => {
    const mask = ["M", "#"]
    if (value.length > 0) {
      if (value[0] === "1") {
        mask[1] = "m"
      }
    }
    return mask.join("")
  },
  tokens,
})

const dayMask = reactive({
  mask: (value: string) => {
    const mask = ["D", "#"]
    if (value.length > 0) {
      if (value[0] === "3") {
        mask[1] = "d"
      }
      if (value[0] === "0") {
        mask[1] = "c"
      }
    }
    return mask.join("")
  },
  tokens,
})

const emitValue = () => {
  emit(
    "update:modelValue",
    format(
      setDateValues(new Date(), {
        date: Number(day.value),
        month: Number(month.value) - 1,
        year: Number(year.value),
      }),
      props.inputFormat,
      { locale: ru }
    )
  )
}

watch(year, async next => {
  await nextTick()
  if (allFieldsAreEmpty.value) {
    return emit("update:modelValue", null)
  }
  if (hasEmptyFields.value) return
  if (next?.length === 4 && next === year.value) {
    emitValue()
  }
})

watch(month, async next => {
  await nextTick()
  if (allFieldsAreEmpty.value) {
    return emit("update:modelValue", null)
  }
  if (hasEmptyFields.value) return
  if (next?.length === 2 && next === month.value) {
    emitValue()
  }
})

watch(day, async next => {
  await nextTick()
  if (allFieldsAreEmpty.value) {
    return emit("update:modelValue", null)
  }
  if (hasEmptyFields.value) return

  if (next?.length === 2 && next === day.value) {
    emitValue()
  }
})

watch(parsedValue, next => {
  if (next) {
    const parsedDay = format(next, props.dayformat, {
      locale: ru,
    })
    const parsedMonth = format(next, props.monthFormat, {
      locale: ru,
    })
    const parsedYear = format(next, props.yearFormat, {
      locale: ru,
    })
    if (parsedDay !== day.value) {
      day.value = parsedDay
    }
    if (parsedMonth !== month.value) {
      month.value = parsedMonth
    }
    if (parsedYear !== year.value) {
      year.value = parsedYear
    }
  } else {
    day.value = null
    month.value = null
    year.value = null
  }
})

const onKeydown = (field: DateInputField, event: KeyboardEvent) => {
  if (["ArrowLeft", "ArrowRight"].includes(event.key)) {
    switch (field) {
      case "day":
        if (event.key === "ArrowRight") {
          if (dayInput.value?.selectionEnd === 2 || dayInput.value?.value) {
            event.preventDefault()
            monthInput.value?.setSelectionRange(0, 0)
            monthInput.value?.focus()
          }
        }
        break
      case "month":
        if (event.key === "ArrowRight") {
          if (monthInput.value?.selectionEnd === 2 || monthInput.value?.value) {
            event.preventDefault()
            yearInput.value?.setSelectionRange(0, 0)
            yearInput.value?.focus()
          }
        }
        if (event.key === "ArrowLeft" && monthInput.value?.selectionEnd === 0) {
          event.preventDefault()
          dayInput.value?.setSelectionRange(3, 3)
          dayInput.value?.focus()
        }
        break
      case "year":
        if (event.key === "ArrowLeft" && yearInput.value?.selectionEnd === 0) {
          event.preventDefault()
          monthInput.value?.setSelectionRange(3, 3)
          monthInput.value?.focus()
        }
    }
  }
}

const onMaskInput = async (
  field: DateInputField,
  event: CustomEvent<MaskaDetail>
) => {
  const target = event.target as HTMLInputElement
  switch (field) {
    case "day":
      if (target.value?.length === 2) {
        monthInput.value?.focus()
      }
      break

    case "month":
      if (target.value?.length === 2) {
        yearInput.value?.focus()
      }
      break
  }
}

const onFocus = (field: DateInputField) => {
  switch (field) {
    case "day":
      dayInput.value?.select()
      break

    case "month":
      monthInput.value?.select()
      break

    case "year":
      yearInput.value?.select()
  }
}
</script>

<template>
  <div class="pskit__dateinput">
    <input
      ref="dayInput"
      v-model="day"
      v-maska:[dayMask]
      type="text"
      placeholder="дд"
      size="1"
      class="pskit__dateinput_input"
      @maska="($event: CustomEvent<MaskaDetail>) => onMaskInput('day', $event)"
      @keydown.capture.stop="onKeydown('day', $event)"
      @focus="onFocus('day')"
    />
    <span class="pskit__dateinput_divider">{{ divider }}</span>
    <input
      ref="monthInput"
      v-model="month"
      v-maska:[monthMask]
      type="text"
      placeholder="мм"
      size="1"
      class="pskit__dateinput_input"
      @maska="
        ($event: CustomEvent<MaskaDetail>) => onMaskInput('month', $event)
      "
      @keydown.capture.stop="onKeydown('month', $event)"
      @focus="onFocus('month')"
    />
    <span class="pskit__dateinput_divider">{{ divider }}</span>
    <input
      ref="yearInput"
      v-model="year"
      v-maska:[yearMask]
      type="text"
      placeholder="гггг"
      size="3"
      class="pskit__dateinput_input pskit__dateinput_input--wide"
      @maska="($event: CustomEvent<MaskaDetail>) => onMaskInput('year', $event)"
      @keydown.capture.stop="onKeydown('year', $event)"
      @focus="onFocus('year')"
    />
  </div>
</template>

<style lang="postcss" scoped>
.pskit__dateinput_input {
  align-self: center;
  background-color: initial;
  border: 0;
  box-sizing: content-box;
  color: #121212;
  display: inline-flex;
  font-family: "PT Astra Sans", "PT Sans", Verdana, sans-serif;
  font-size: 0.875rem;
  height: 16px;
  line-height: 1.3rem;
  text-align: center;
  text-align: left;
  width: 2ch;
  padding: 0;
}

.pskit__dateinput_input:first-child {
  padding-left: 0.285em;
}
.pskit__dateinput_input:focus {
  box-shadow: none;
  outline: 0;
}

.pskit__dateinput_input--wide {
  width: calc(4ch + 0.285em);
}

.pskit__dateinput_divider {
  color: #121212;
  vertical-align: bottom;
  line-height: 1.3rem;
}
</style>
