import Main from "./ContextTab/Main.vue"
import { Inkline } from "@inkline/inkline"
import { setupCalendar } from "v-calendar"
import { createApp } from "vue"
import messageHub from "@/ContextTab/services/messageHubClient"
import { tabMetrics } from "@/ContextTab/services/tabMetrics"
import { messageTypes } from "@/lib/messageHub/messages"
import { router } from "./ContextTab/router"
import "@/assets/styles/design-system/index.css"
import "@inkline/inkline/css/index.scss"
import "@inkline/inkline/css/utilities.scss"
// eslint-disable-next-line import/no-unresolved
import "virtual:svg-icons-register"
import "@/assets/styles/index.pcss"
import { AppInitState } from '@/ContextApp/stores/init'


let isAppCreated = false

window.addEventListener("message", event => {
  if (event.data?.event === "PSExtReady") {
    window.extensionId = event.data.id
  }
})

if (globalThis.SharedWorker) {
  const appWorker = new SharedWorker(new URL("./ContextApp/index.ts", import.meta.url), {
    type: "module",
    name: "PsAppWorker",
  })

  // постоянное общение с воркером, чтобы браузер точно по-тихому не убил воркер
  if (appWorker instanceof SharedWorker) {
    appWorker.port.start()
    setInterval(() => appWorker.port.postMessage("PING"), 10_000)
  }

} else {
  // TODO: for mobile, as sharedWorkers not supported for Chrome for Android yet?
  new Worker(
    new URL("./ContextApp/index.ts", import.meta.url),
    {
      type: "module",
      name: "PsAppWorker",
    }
  )
}

messageHub.addMessageListener({
  metaFilter(meta) {
    return (
      // from middleware/init, init state changed
      messageTypes.byId(meta.typeId).name === messageTypes.names.service
      && meta.signal === "initStateChange"
    )
  },
  callback(message) {
    if (
      [AppInitState.started, AppInitState.ready].includes(message.data.payload)
      && !isAppCreated
    ) {
      const app = createApp(Main)

      app.use(Inkline, {
        locale: "ru",
        size: "sm",
        colorMode: () => "colorful-light",
      })

      tabMetrics.init(app)

      app.use(setupCalendar, {})
      app.use(router)
      app.mount("#app")
      isAppCreated = true
    }
  },
})

messageHub.sendMessage(
  {
    // to middleware/init, ask ready, force worker send "initStateChange"
    typeId: messageTypes.byName("service").id,
    signal: "checkWorkerInit",
  },
  null
)

messageHub.addMessageListener({
  metaFilter: (meta) =>
    messageTypes.byId(meta.typeId).name === messageTypes.names.service
    && meta.signal === "reload",
  callback: () => globalThis.location.reload(),
})
