<script lang="ts" setup>
import PsCheckbox from "@/ContextTab/components/UI/PsCheckbox.vue"
import PsAvatar from "@/ContextTab/components/Avatar.vue"
import { escapeRegExp as $escapeRegExp } from "lodash-es"
import { computed } from "vue"
import { formatUsername } from "@/utils/formatUsername"
import { translateLayout } from "@/utils/translateLayout"
import format from "date-fns/format"
import isToday from "date-fns/isToday"
import differenceInDays from "date-fns/differenceInDays"
import isThisYear from "date-fns/isThisYear"
import { ru } from "date-fns/locale"
import type { IUser } from "@/ContextTab/modules/news/published/components/types"

interface Props {
  id: number
  entry: IUser
  isChecked: boolean
  searchQuery: string
  isSelected?: boolean
  isCheckable?: boolean
  useLayoutTranslation?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  isSelected: false,
  isCheckable: false,
  useLayoutTranslation: false,
})

const emit = defineEmits(["toggle"])

const initials = computed(() =>
  props.entry.is_group ? "" : getInitials(name.value)
)
const name = computed(() => formatUsername(props.entry.name))
const code = computed(() => props.entry.code || "")
const status = computed(() => {
  if (props.entry.is_online)
    return "Онлайн"

  if (props.entry.last_seen) {
    // return `Последнее посещение ${props.entry.last_seen}`
    return `Последнее посещение ${getFormattedDate(
      props.entry.last_seen
    )}`
  }
  return ""
})

function highlightHTML(field: string): string {
  if (!props.searchQuery.length) {
    return field
  }

  const query = formatUsername(props.searchQuery)

  // FIXME: здесь алгоритм подсветки дублирует алгоритм поиска. вместо этого сервер должен возвращать юзера и match. либо список юзеров и поиск должен быть полностью на фронте
  const translatedQuery = translateLayout(query)

  // Эскейпим регулярку, чтобы не искать по спец. символам
  let escapedRegex = $escapeRegExp(query)

  // Добавляем к регулярке транслированную раскладку клавиатуры
  if (props.useLayoutTranslation) {
    escapedRegex += "|" + $escapeRegExp(translatedQuery)
  }

  const regex = new RegExp(escapedRegex, "gi")
  return field.replace(regex, "<mark>$&</mark>")
}

function getInitials(fullName: string): string {
  const names = fullName.split(" ")
  let initials = names[0].substring(0, 1).toUpperCase()

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase()
  }
  return initials
}

function getFormattedDate(dateString: string) {
  const date = new Date(dateString)
  const timeStr = format(date, 'HH:mm', { locale: ru })

  if (isToday(date)) return `в ${timeStr}`
  if (differenceInDays(Date.now(), date) < 1) return `вчера в ${timeStr}`
  if (!isThisYear(date))
    return `${format(date, 'd MMMM yyyy', { locale: ru })} в ${timeStr}`
  return `${format(date, 'd MMMM', { locale: ru })} в ${timeStr}`
}
</script>

<template>
  <div
    class="ps-users-list-entry"
    :class="{ selected: isSelected }"
    @click.stop.prevent="emit('toggle')"
  >
    <PsCheckbox
      v-if="isCheckable"
      :checked="isChecked ? 'included' : 'unchecked'"
      checkedValue="included"
      uncheckedValue="unchecked"
    />
    <div class="info">
      <div class="name" v-html="highlightHTML(name)" />
      <div v-if="entry.is_group" class="meta">
        Группа •
        <span v-html="highlightHTML(code)" />
      </div>
      <template v-else>
        <div class="meta">
          Пользователь •
          <span v-html="highlightHTML(code)" />
          <div class="status" :class="{ online: entry.is_online }">
            {{ status }}
          </div>
        </div>
      </template>
    </div>
    <slot name="optional-data" v-bind="{ entry }"></slot>

    <PsAvatar v-if="!entry.is_group" :image="entry.image" :alt="name" :placeholder="initials" :indicator="true"
      :online="entry.is_online" />
  </div>
</template>

<style scoped>
.ps-users-list-entry {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
  padding: 0.375rem 0.625rem 0.375rem 0.75rem;
  border-bottom: 1px solid #f0f0f0;
  background: transparent;
  transition:
    background 0.12s linear,
    border 0.12s linear;

  &.selected {
    border-bottom-color: transparent;
  }

  .info {
    flex-grow: 1;
    flex-shrink: 1;
  }

  .meta {
    margin-top: 2px;
    font-size: 0.75rem;
  }

  .status {
    font-size: 0.75rem;

    &.online {
      color: var(--color-success);
    }
  }
}
</style>
