<script setup lang="ts">
import PsIcon from "@/ContextTab/components/UI/PsIcon.vue"
import VirtualTree from "@/ContextTab/components/UI/VirtualTree/VirtualTree.vue"
import SearchField from "@/ContextTab/components/UI/SearchField.vue"
import { ref, useTemplateRef } from "vue"
import type { TreeSearchParams } from "./useTreeSearch"

const props = withDefaults(
  defineProps<{
    items: any
    searchParams: TreeSearchParams
    initialSize?: number
    emptyText?: string
    buffer?: number
    trackShift?: number
    showDepth?: number
    padding?: number
    idField?: string
    sortField?: string
    placeholder?: string
  }>(),
  {
    initialSize: 20,
    emptyText: "Ничего не найдено",
    buffer: 100,
    padding: 20,
    trackShift: 1,
    showDepth: 1,
    idField: "id",
    sortField: "pos",
    placeholder: "Поиск по кодам",
  }
)
const tree = useTemplateRef('tree')

const query = ref(props.searchParams.initialQuery)

const onSearch = (updated: string) => {
  query.value = updated
}
const scrollToTop = () => {
  tree.value?.scrollToTop()
}
defineExpose({
  scrollToTop,
})
</script>

<template>
  <div class="wrapper">
    <div v-if="searchParams.enabled" class="search">
      <SearchField
        :initialQuery="query"
        :autofocus="true"
        :placeholder="placeholder"
        class="search-field"
        @input="onSearch"
      >
        <template #prepend-icon>
          <PsIcon type="font" name="search" />
        </template>
        <template #append-icon>
          <PsIcon v-show="query" type="font" name="close" />
        </template>
      </SearchField>
    </div>
    <VirtualTree
      ref="tree"
      :items="items"
      :idField="idField"
      :initialSize="initialSize"
      :buffer="buffer"
      :skipPrepare="true"
      :trackShift="trackShift"
      :showDepth="showDepth"
      :openPadding="padding"
      :searchParams="searchParams"
      :searchQuery="query"
      :sortField="sortField"
    >
      <template #default="{ item, selected, opened }">
        <slot v-bind="{ item, selected, opened }" />
      </template>
      <template #empty>
        <p>{{ emptyText }}</p>
      </template>
    </VirtualTree>
  </div>
</template>

<style scoped lang="postcss">
.wrapper {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.search-field {
  border-radius: 3px;
  border: 1px solid #b2ada3;
  background: #fff;
  max-width: 500px;
  padding: 0 0.5rem;
  margin-bottom: 1rem;
}
</style>
