import decode from "jwt-decode"
import type { PsApiV1 } from "ps-client"
import { ApiError } from "ps-client"
import { ref, computed } from "vue"
import api from "@/ContextApp/services/api"
import { appMetrics } from "@/ContextApp/services/appMetrics"
import storage from "@/ContextApp/services/storage"

interface TokenData {
  fresh: boolean
  iat: number
  jti: string
  type: "access" | "refresh"
  sub: number
  nbf: number
  exp: number
  permissions: string[]
}

export enum AuthStatus {
  initial = 0,
  notAuthorized = 1,
  authorized = 2,
}

function define() {
  const accessToken = ref<string | null>(null)
  const refreshToken = ref<string | null>(null)
  const login = ref<string | null>(null)

  /* TODO: Возможно стоит сделать computed */
  const tokenData = ref<TokenData | null>(null)

  const authorized = ref<AuthStatus>(AuthStatus.initial)
  const error = ref<ApiError | null>(null)

  const userId = computed(() => tokenData.value?.sub ?? null)

  async function loadFromStorage() {
    const storedRefreshToken = await storage.getItem("refreshToken")
    if (storedRefreshToken?.data) {
      refreshToken.value = storedRefreshToken.data
    }
    const storedAccessToken = await storage.getItem("accessToken")
    if (storedAccessToken?.data) {
      accessToken.value = storedAccessToken.data
      tokenData.value = decode<TokenData>(storedAccessToken.data)
      appMetrics.setUser({
        id: tokenData.value.sub,
      })
      const storedLogin = await storage.getItem("login")
      if (storedLogin?.data) {
        login.value = storedLogin?.data
        appMetrics.setUser({
          email: storedLogin?.data,
        })
      }
      authorized.value = AuthStatus.authorized
    } else {
      authorized.value = AuthStatus.notAuthorized
    }
  }

  async function logout() {
    storage.clear()
    accessToken.value = null
    refreshToken.value = null
    login.value = null
    authorized.value = AuthStatus.notAuthorized
  }

  async function updateToken(access: string, refresh: string) {
    if (!access && !refresh) {
      return {}
    }
    accessToken.value = access
    refreshToken.value = refresh
    tokenData.value = decode<TokenData>(access)
    await storage.setItem("accessToken", access)
    await storage.setItem("refreshToken", refresh)
    appMetrics.setUser({
      id: tokenData.value.sub,
    })
    return { access, refresh }
  }

  async function authorize({ username, password }: PsApiV1.AuthPostIn) {
    error.value = null
    const response = await api.auth.login({ username, password })
    if (!response) {
      throw new Error("No response from api.auth.login")
    }
    if (response instanceof ApiError || !response) {
      error.value = response
      authorized.value = AuthStatus.notAuthorized
      if (!response) {
        appMetrics.sendException("No response from api.auth.login", { username })
      }
      return response
    }
    updateToken(response.access_token, response.refresh_token)
    appMetrics.setUser({
      id: tokenData.value?.sub,
      email: username,
    })

    login.value = username
    storage.setItem("login", username)
    authorized.value = AuthStatus.authorized
    return response
  }

  async function requestPasswordReset({ username }: PsApiV1.LostPasswordPostIn) {
    error.value = null
    const response = await api.auth.requestPasswordReset({ username })
    if (response instanceof ApiError) {
      error.value = response
    }
    return response
  }

  async function resetPassword(token: PsApiV1.ResetPasswordPostIn) {
    error.value = null
    const response = await api.auth.resetPassword(token)
    if (response instanceof ApiError) {
      error.value = response
    }
    return response
  }

  function setNotAuthorized() {
    authorized.value = AuthStatus.notAuthorized
  }

  return {
    accessToken,
    authorized,
    refreshToken,
    tokenData,
    error,
    login,
    userId,
    authorize,
    setNotAuthorized,
    loadFromStorage,
    logout,
    updateToken,
    requestPasswordReset,
    resetPassword
  }
}

export type AuthStore = ReturnType<typeof define>

export default {
  define,
}
