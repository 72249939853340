<script setup lang="ts">
import VirtualListItem from "./VirtualListItem.vue"
import { useElementVisibility } from "@vueuse/core"
import { ref, computed, watch, watchEffect, onMounted, reactive, useTemplateRef } from "vue"
import useKeyboard from "./useKeyboard"
import useUpdateScheduler from "./useUpdateScheduler"
import useVisibility from "./useVisibility"
import { createView, SizeRecord } from "./utils"

const props = withDefaults(defineProps<{
  items?: any[]
  initialSize?: number
  selectedId?: number | string
  keyboardEnabled?: boolean
  vimNavigation?: boolean
  idField?: string
}>(), {
  items: undefined,
  initialSize: 100,
  selectedId: undefined,
  keyboardEnabled: false,
  vimNavigation: false,
  idField: "id",
})

const emit = defineEmits(["reachedStart", "reachedEnd", "selected"])

defineSlots<{
  before(): any,
  default(props: { item: any, selected: boolean, index: number }): any,
  after(): any,
  empty(): any,
}>()

const ricShim = function (
  handler: (arg: any) => void,
  { timeout = 5000 } = {}
) {
  const start = Date.now()
  return setTimeout(function () {
    handler({
      didTimeout: false,
      timeRemaining: function () {
        return Math.max(0, timeout - (Date.now() - start))
      },
    })
  }, 1)
}

const requestIdleCallback = globalThis.requestIdleCallback || ricShim

const views = reactive<{ pool: Record<string, any> }>({ pool: {} })
let itemToViews: Record<string, any> = {}
const sizeMap: Record<string, any> = {}

const wrapper = useTemplateRef("wrapper")
let wrapperSize = 0

const scroller = useTemplateRef('scroller')
const listSize = ref(0)
let scrollerStart = 0

const { tasks } = useUpdateScheduler()

const resizeObserver = new ResizeObserver(events => {
  wrapperSize = events[events.length - 1].contentRect.height
  handleScroll()
})

const selectedIndex = ref(0)

useKeyboard(
  props,
  emit,
  selectedIndex,
  wrapper,
  sizeMap,
  scrollToItem,
  () => wrapperSize,
  props.idField,
  tasks
)

const { calculateVisibility } = useVisibility({
  getItems: () => props.items ?? [],
  getSizeMap: () => sizeMap,
  getScrollerStart: () => scrollerStart,
  getWrapperSize: () => wrapperSize,
  idField: props.idField,
})

const itemIds = computed(() =>
  props.items?.map(item => item[props.idField].toString()) ?? []
)

function clearSizeMap() {
  for (const key in sizeMap) {
    if (
      performance.now() - sizeMap[key].updated > 60000 &&
      !itemIds.value.includes(key)
    ) {
      delete sizeMap[key]
    }
  }
}

function updateSizeMap(reset = false) {
  if (props.items) {
    for (let i = 0; i < props.items.length; i++) {
      const item = props.items[i]
      let record
      if (reset) {
        record = {}
      } else {
        record = sizeMap[item?.[props.idField]] ?? {}
      }
      const size = record.size ?? props.initialSize
      sizeMap[item[props.idField]] = SizeRecord(
        sizeMap,
        props.items,
        size,
        i,
        props.idField,
        performance.now()
      )
    }
  }

  requestIdleCallback(() => {
    clearSizeMap()
  })
}

function updateVisibleItems() {
  const visible = calculateVisibility()

  const freeViews = { ...views.pool }

  const newItemToViews: Record<string, any> = {}

  const itemsWithoutViews = []

  for (let i = 0; i < visible.length; i++) {
    const item = visible[i]

    if (itemToViews[item[props.idField]]) {
      const view = itemToViews[item[props.idField]]
      delete freeViews[view.uid]
      newItemToViews[item[props.idField]] = view
    } else {
      itemsWithoutViews.push(item)
    }
  }

  const freeViewKeys = Object.keys(freeViews)

  for (let i = 0; i < itemsWithoutViews.length; i++) {
    const item = itemsWithoutViews[i]
    let view

    if (freeViewKeys.length) {
      const key = freeViewKeys.pop()

      if (key) {
        view = freeViews[key]
        view.item = item
        views.pool[view.uid] = view
      }
    } else {
      view = createView()
      view.item = item
      views.pool[view.uid] = view
    }

    itemToViews[item[props.idField]] = view
  }

  for (let i = 0; i < freeViewKeys.length; i++) {
    delete views.pool[freeViewKeys[i]]
  }

  listSize.value =
    Math.ceil(
      sizeMap[props.items?.[props.items.length - 1]?.[props.idField]]?.getEnd()
    ) ?? listSize.value

  itemToViews = newItemToViews
}

let ready = false

function handleScroll() {
  if (!ready) return

  tasks.scroll = () => {
    if (wrapper?.value) {
      scrollerStart = -wrapper.value.scrollTop
    }
    updateVisibleItems()
  }
}

function updateRecord(index: number) {
  for (let i = index; i < props.items.length; i++) {
    const item = props.items[i]
    const sizeRecord = sizeMap[item[props.idField]]
    if (!sizeRecord) return
    if (i === 0) {
      sizeRecord._start = 0
    } else {
      const prevItem = props.items[i - 1]
      const prevSizeRecord = sizeMap[prevItem[props.idField]]
      if (!prevSizeRecord) return
      sizeRecord._start = prevSizeRecord.getEnd() + 1
    }
    sizeRecord.updated = Date.now()
  }
}

function handleElementResize({
  item,
  size,
  view,
  index,
}: {
  item: any
  size: number
  view: any
  index: number
}) {
  tasks.views[view.uid] = {
    index,
    handler: () => {
      if (
        !wrapper?.value
        || !item
        || size === 0
      ) {
        return
      }

      const mapItem = sizeMap[item[props.idField]]
      if (mapItem && mapItem.size !== size) {
        mapItem.size = size
        updateRecord(mapItem.index)
        listSize.value =
          sizeMap[props.items[props.items.length - 1][props.idField]]?.getEnd()
      }
    }
  }
}

function scrollToItem(index: number) {
  const item = props.items[index]
  const start = sizeMap[item[props.idField]]?.getStart() ?? null
  if (start !== null && wrapper.value) {
    wrapper.value.scrollTop = start
  }
}

function scrollToTop() {
  if (wrapper.value) {
    wrapper.value.scrollTop = 0
  }
}

watch(
  () => props.items,
  (next, prev) => {
    if (next !== prev) {
      ready = true
      updateSizeMap()
      updateVisibleItems()
      if (next.length === 0) {
        listSize.value = 0
        scrollerStart = 0
      }
    }
  }
)

watchEffect(
  () => {
    if (props.selectedId !== null) {
      if (!props.items?.length) {
        return
      }
      const index = props.items.findIndex(
        item => item?.[props.idField] === props.selectedId
      )
      if (index !== selectedIndex.value) {
        selectedIndex.value = index
      }
    }
  },
  { flush: "post" }
)

onMounted(() => {
  if (props.selectedId !== null && props.items?.length) {
    selectedIndex.value = props.items.findIndex(
      item => item[props.idField] === props.selectedId
    )
  }
  if (wrapper.value) {
    const { height } = wrapper.value.getBoundingClientRect()
    wrapperSize = height

    resizeObserver.observe(wrapper.value)
  }

  if (props.items) {
    ready = true
    updateSizeMap()
    updateVisibleItems()
  }
})

// -------------------------------------------------------------------------
//               Управление событиями reachedStart и reachedEnd
// -------------------------------------------------------------------------

const before = useTemplateRef('before')
const after = useTemplateRef('after')

const beforeIsVisible = useElementVisibility(before)
const afterIsVisible = useElementVisibility(after)

watch(beforeIsVisible, next => {
  emit("reachedStart", next)
})

watch(afterIsVisible, next => {
  emit("reachedEnd", next)
})
// -------------------------------------------------------------------------

function onSelect(item: any) {
  tasks.select = () => {
    emit("selected", item)
  }
}

defineExpose<{
  scrollToItem: typeof scrollToItem
  scrollToTop: typeof scrollToTop
}>({
  scrollToItem,
  scrollToTop,
})
</script>

<template>
  <div
    ref="wrapper"
    class="pskit__virtual-list_wrapper"
    @scroll="handleScroll"
  >
    <div
      ref="before"
      class="pskit__virtual-list_before"
    >
      <slot name="before" />
    </div>
    <div
      ref="scroller"
      class="pskit__virtual-list_scroller"
      :style="{ height: `${listSize}px` }"
    >
      <template v-if="items?.length">
        <VirtualListItem
          v-for="view in views.pool"
          :key="view.uid"
          :view="view"
          :idField="idField"
          :index="sizeMap[view.item[idField]]?.index"
          :initialSize="initialSize"
          :size="sizeMap[view.item[idField]]?.size"
          :start="sizeMap[view.item[idField]]?.getStart()"
          :selected="selectedId === view.item[idField]"
          :handleResize="handleElementResize"
          :onSelect="onSelect"
        >
          <template #default="{ item, selected, index }">
            <slot v-bind="{ item, selected, index}" />
          </template>
        </VirtualListItem>
      </template>
      <template v-else>
        <slot name="empty" />
      </template>
    </div>
    <div ref="after" class="pskit__virtual-list_after">
      <slot name="after" />
    </div>
  </div>
</template>

<style scoped lang="postcss">
.pskit__virtual-list_wrapper {
  height: 100%;
  overflow-y: auto;
  position: relative;
}
.pskit__virtual-list_scroller {
  box-sizing: border-box;
  min-height: 100%;
  will-change: scroll-position;
}
.pskit__virtual-list_empty {
  margin: 0 auto;
  height: 100%;
}
</style>
