<script setup lang="ts">
import VirtualTreeItem from "./VirtualTreeItem.vue"
import VirtualList from "../VirtualList/VirtualList.vue"
import { computed, watchEffect, ref, type Ref, useTemplateRef } from "vue"
import {
  normalizeChildrenByTrack,
  makeItems,
  makeList,
  defaultSearch,
  makeDefaultPredicate,
  makeSearchParams, type DefaultSearchParams,
} from "./utils"

const props = withDefaults(defineProps<{
  items?: Record<string, any>
  buffer?: number
  initialSize?: number
  itemsPrepared?: boolean
  listPrepared?: boolean
  keyboardEnabled?: boolean
  keyboardThrottle?: number
  openPadding?: number
  searchParams?: Partial<DefaultSearchParams>
  searchQuery?: string
  showDepth?: number
  skipPrepare?: boolean
  trackShift?: number
  vimNavigation?: boolean
  selectedId?: number | string | undefined
  sortField?: string
  idField?: string
}>(), {
  items: undefined,
  buffer: 100,
  initialSize: 100,
  keyboardEnabled: false,
  keyboardThrottle: 0,
  openPadding: 20,
  searchParams: () => makeSearchParams(),
  searchQuery: "",
  showDepth: 1,
  // FIXME
  skipPrepare: true,
  trackShift: 1,
  vimNavigation: false,
  selectedId: undefined,
  sortField: "id",
  idField: "id",
})

const emit = defineEmits(["reachedStart", "reachedEnd", "selected"])
const searchParams = makeSearchParams(props.searchParams)

defineSlots<{
  after(): any,
  default(props: { item: any, selected: boolean, opened: boolean }): any,
  before(): any,
  empty(): any,
}>()

const list = useTemplateRef('list')

const searchMode = computed(
  () => props.searchQuery?.toString().length >= searchParams.minimalQueryLength
)

const filterMode = computed(() => false)

const processed = computed(() => {
  if (!props.items) {
    return {}
  }
  if (props.skipPrepare) {
    return props.items
  }
  const processed = makeItems(props.items, "children", props.idField)
  normalizeChildrenByTrack(processed)
  return processed
})

const fullList = computed(() => makeList(processed.value, props.sortField))

const filteredList = computed(() => {
  if (searchMode.value) {
    return search()
  } else {
    return fullList.value
  }
})

type InternalMap = {
  visualMap: {
    [key: string]: boolean
  }
}
const internal: InternalMap = {
  visualMap: {},
}
const visibleList: Ref<any[]> = ref([])

function updateVisibleList(clearVisualMap = false) {
  if (clearVisualMap) {
    internal.visualMap = {}
  }
  if (!props.items) {
    return
  }

  const visible = []

  // Проходим по всем отфильтрованным элементам
  for (let i = 0; i < filteredList.value.length; i++) {
    const item = filteredList.value[i]
    /* Если компонент в режиме поиска или фильтрации,
     * то в списке только отфильтрованные элементы и их родители,
     * нужно показать всё
     */
    if (searchMode.value || filterMode.value) {
      const parent = props.items[item.track[item.track.length - 1]]
      if (parent && internal.visualMap[parent[props.idField]] === false) {
        continue
      }

      visible.push(item)
      continue
    }

    // Учитываем props trackShift при повторном рендере
    if (
      item.track.length - props.trackShift >= 0 &&
      item.track.length <= props.showDepth
    ) {
      visible.push(item)
      continue
    }
    // Если элемент указан в visualMap — нужно его показать
    if (internal.visualMap[item[props.idField]]) {
      visible.push(item)
      continue
    }
    // Если родительский элмент в visualMap,
    // значит, он раскрыт и текущий элемент надо показать
    if (internal.visualMap[item.track[item.track.length - 1]]) {
      visible.push(item)
    }
  }
  visibleList.value = visible
}

function search() {
  const predicates = []
  if (props.searchQuery.length >= searchParams?.minimalQueryLength) {
    predicates.push(
      makeDefaultPredicate({
        searchString: props.searchQuery,
        params: {
          fields: searchParams.fields,
          caseSensitive: searchParams.caseSensitive,
        },
      })
    )
  }
  predicates.push(...searchParams.predicates)
  const searchFunc = searchParams.searchFunction ?? defaultSearch
  return searchFunc({
    list: fullList.value,
    items: props.items,
    predicates,
    idField: props.idField,
  })
}

function scrollToTop() {
  if (list.value) {
    list.value?.scrollToTop()
  }
}

function toggle(item: any) {
  if (!item.children?.length) return
  const newState = !internal.visualMap[item[props.idField]]
  if (!newState) {
    hideRecursive(item)
  }
  internal.visualMap[item[props.idField]] = newState
  updateVisibleList()
}

function hideRecursive(item: any) {
  if (!item?.children?.length) return
  for (let i = 0; i < item.children.length; i++) {
    const child: any = processed.value[item.children[i] as string]
    if (child?.children?.length) {
      internal.visualMap[item.children[i]] = false
      hideRecursive(child)
    }
  }
}

watchEffect(() => {
  if (props.items) {
    updateVisibleList(true)
  }
})

defineExpose({
  scrollToTop,
})
</script>

<template>
  <div class="pskit__virtual-tree-wrapper">
    <VirtualList
      ref="list"
      :initialSize="initialSize"
      :items="visibleList"
      :idField="idField"
      :selectedId="selectedId"
      :keyboardEnabled="keyboardEnabled"
      :keyboardThrottle="keyboardThrottle"
      :vimNavigation="vimNavigation"
      @selected="toggle"
      @reachedStart="emit('reachedStart')"
      @reachedEnd="emit('reachedEnd')"
    >
      <template #before>
        <slot name="before" />
      </template>
      <template #default="{ item, selected }">
        <VirtualTreeItem
          :item="item"
          :selected="selected"
          :trackShift="trackShift"
          :openPadding="openPadding"
          :opened="
            Boolean(
              item?.children?.length && internal.visualMap[item?.[idField]]
            )
          "
        >
          <template #default="{ opened }">
            <slot v-bind="{ item, selected, opened }" />
          </template>
        </VirtualTreeItem>
      </template>
      <template #empty>
        <slot name="empty"></slot>
      </template>
      <template #after>
        <slot name="after" />
      </template>
    </VirtualList>
  </div>
</template>

<style scoped lang="postcss">
.pskit__virtual-tree-wrapper {
  height: 100%;
  overflow: hidden;
  position: relative;
}
</style>
