<script setup lang="ts">
import { useContainerBreakpoints } from "@/ContextTab/composables/useContainerBreakpoints"
import { ref, computed, provide, useTemplateRef } from "vue"
import { ContainerBreakpointsKey } from "@/injectionKeys"
import type { SizeBreakpoints } from '@/ContextTab/composables/types'

const props = defineProps<{
  background?: string
  breakpoints?: SizeBreakpoints
}>()

defineSlots<{
  default(props: any): any
  left(props: any): any
  right(props: any): any
}>()

const toolbar = useTemplateRef('toolbar')

const containerBreakpoints = useContainerBreakpoints(toolbar, computed(() => props.breakpoints))
provide(ContainerBreakpointsKey, containerBreakpoints) // for

</script>

<template>
  <div
    ref="toolbar"
    class="panel-toolbar"
  >
    <div
      v-if="$slots.left"
      class="left-block"
    >
      <slot name="left" />
    </div>
    <div class="center-block">
      <slot />
    </div>
    <div
      v-if="$slots.right"
      class="right-block"
    >
      <slot name="right" />
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.panel-toolbar {
  align-items: center;
  z-index: 1;
  padding: 0.5rem 0.75rem;
  display: flex;
  flex-wrap: nowrap;
  background-color: v-bind(background);
  box-sizing: border-box;
  box-shadow: var(--toolbar-shadow);
  width: 100%;
}

.left-block,
.right-block,
.center-block {
  display: flex;
  flex-shrink: 1;
  align-items: center;
  height: 100%;
  flex-wrap: nowrap;
}

.center-block {
  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }
}

.left-block,
.right-block {
  gap: 0.5rem;
}

.left-block {
  justify-content: start;
}

.right-block {
  justify-content: end;
  margin-left: auto;
}
</style>
