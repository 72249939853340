import { deepEqual } from "fast-equals"
import type { PsApiV2 } from "ps-client"
import { ApiError } from "ps-client"
import { reactive, ref, watch, computed, toRaw } from "vue"
import api from "@/ContextApp/services/api"
import { GatewaySubType } from "@/ContextApp/services/gateway/constants"
import appStore, { type StoreGatewaySubscriptionIdentification } from '@/ContextApp/services/store'
import {
  checkByUsersFilter,
  checkByCodesFilter,
  checkByHideRepublicationsFilter,
  checkNewsStatus,
  checkByFeedFilter,
  checkByDatesFilter,
  checkByNewsEvent,
} from "@/lib/setPredicates/news"
import { useGC } from "@/lib/storeHelpers"
import { filterPrivate } from "@/utils/filters"
import pipe from "@/utils/pipe"
import type { PublishedLocalFiltersStore } from "@/ContextApp/stores/news/published/localFilters"
import type { PublishedFiltersStore } from "@/ContextApp/stores/news/published/filters"
import type { NewsStore } from "@/ContextApp/stores/news"
import type { RefsStore } from "@/ContextApp/stores/references"
import type { UserProfileStore } from "@/ContextApp/stores/userProfile"
import type { GroupsAndUsersStore } from "@/ContextApp/stores/groupsAndUsers"
import type { CodesStore } from "@/ContextApp/stores/codes"
import type { GatewaySubscriptionHubMessage } from '@/ContextApp/middleware/gateway'
import type { NewsSubscriptionData } from '@/ContextApp/services/gateway/types'

const MAX_LIST_SIZE = 100
export const MAX_SIZE_HEAD_SET = 1000

function define(_: string, contextId: string | null) {
  const error = ref<any>(null)

  const $newsStore = () => appStore.getStore<"news", NewsStore>("news")
  const $refStore = () =>
    appStore.getStore<"references", RefsStore>("references")
  const $userProfileStore = () =>
    appStore.getStore<"userProfile", UserProfileStore>("userProfile")
  const $groupsAndUsersStore = () =>
    appStore.getStore<"groupsAndUsers", GroupsAndUsersStore>("groupsAndUsers")
  const $codesStore = () => appStore.getStore<"codes", CodesStore>("codes")
  const $localFiltersStore = () =>
    appStore.getStore<"publishedLocalFilters", PublishedLocalFiltersStore>(
      "publishedLocalFilters",
      contextId
    )
  const $filterStore = () =>
    appStore.getStore<"publishedFilters", PublishedFiltersStore>(
      "publishedFilters",
      contextId
    )

  const VERTICAL_PANEL_LIMIT = 30
  const HORIZONTAL_PANEL_LIMIT = 60

  const set = reactive<number[]>([])
  useGC($newsStore()?.news, set)

  const newsList = ref<PsApiV2.NewsDetailed[]>([])

  type LoadParams = {
    cursor?: string
    reverse?: boolean
    [key: string]: any
  }
  const params = reactive<LoadParams>({
    cursor: undefined,
    reverse: false,
  })

  const isFetching = ref(true)

  const datesFilter = computed(
    () => $localFiltersStore()?.dates ?? { start: null, end: null }
  )

  const getFilterParams = () => {
    const filters = pipe(toRaw, filterPrivate)($filterStore()?.filters ?? {})
    const processed = Object.keys(filters).reduce(
      (acc: Record<string, any>, cur: string) => {
        acc[cur] = filters[cur]?.join(",") ?? []
        return acc
      },
      {}
    )
    if (
      filters.user_group_ids?.length ||
      filters.user_group_excluded_ids?.length
    ) {
      const userIncludedIds = filters?.users_ids ?? []
      const userExcludedIds = filters?.users_excluded_ids ?? []
      const allUserIds = [...userIncludedIds, ...userExcludedIds]

      const groupIds = (filters.user_group_ids ?? []).reduce(
        (acc: (string | number)[], val: string | number) => {
          const groupChildren =
            $groupsAndUsersStore()?.groupAndUsersById[`${val}G`]?.children ?? []
          return [
            ...acc,
            ...groupChildren.filter(
              (child: string | number) => !allUserIds.includes(child)
            ),
          ]
        },
        userIncludedIds
      )

      const groupExcludedIds = (filters.user_group_excluded_ids ?? []).reduce(
        (acc: (string | number)[], val: string | number) => {
          const groupChildren =
            $groupsAndUsersStore()?.groupAndUsersById[`${val}G`]?.children ?? []
          return [
            ...acc,
            ...groupChildren.filter(
              (child: string | number) => !allUserIds.includes(child)
            ),
          ]
        },
        userExcludedIds
      )

      processed.user_ids = groupIds.join(",")
      processed.user_excluded_ids = groupExcludedIds.join(",")
    }
    return processed
  }

  const hideRepublications = computed(
    () => $localFiltersStore()?.hideRepublications ?? null
  )

  const reqLimit = computed(() =>
    $userProfileStore()?.profile?.publishedPanelMode === 2
      ? HORIZONTAL_PANEL_LIMIT
      : VERTICAL_PANEL_LIMIT
  )

  const hasMoreItems = ref(true)

  watch(params, next => {
    hasMoreItems.value = next.cursor !== null
  })

  async function loadNext() {
    error.value = null
    isFetching.value = true

    const requestParams = {
      ...params,
      limit: reqLimit.value,
      from_dt: datesFilter.value?.start,
      to_dt: datesFilter.value?.end,
      hide_republications: hideRepublications.value,
      ...getFilterParams(),
    }

    const response = searchMode.value
      ? await api.news.search({
        ...requestParams,
        ...$localFiltersStore()?.getSearchParams(),
      })
      : await api.news.fetchMoreSpecific(requestParams, "published")

    if (response instanceof ApiError) {
      error.value = response

      if (response.errors && response.status === 422) {
        error.value.description =
          response.errors[0]?.detail || response.errors[0]?.title
      }
    } else {
      params.cursor = response.next_cursor
      const mapped =
        response.data
          ?.map(item => item.id)
          .filter((id: number) => !set.includes(id)) ?? []

      set.push(...mapped)

      response.data?.forEach(newsItem => {
        $newsStore()?.setNewsItem(newsItem.id, newsItem)
      })
    }
    isFetching.value = false
    return response
  }

  function reload() {
    params.cursor = undefined
    set.length = 0
    newsList.value.length = 0
    isFullHeadSet.value = false
    lockedHeadSet.length = 0
    isLockedHead.value = searchMode.value
    loadNext()
  }

  function slice(size = MAX_LIST_SIZE) {
    if (set.length > MAX_LIST_SIZE && isFetching.value === false) {
      const item = $newsStore()?.news[set[MAX_LIST_SIZE]]
      params.cursor = item?.status_modified_at
      set.length = size
    }
  }

  function unshiftSet(id: number, makeSlice = false) {
    if (set.includes(id)) return
    set.unshift(id)
    if (makeSlice) {
      slice()
    }
  }

  watch(set, next => {
    newsList.value = next.map(id => $newsStore()?.news[id])
  })

  $newsStore()?.$subscribe((_, state) => {
    newsList.value = set.map(id => state.news[id])
  })

  // -------------------------------------------------------------------------
  //            Переключение на режим поиска и обратно
  // -------------------------------------------------------------------------
  const searchMode = ref(false)
  const showSearch = ref(false)

  const updateShowSearch = (show: boolean) => {
    showSearch.value = show
  }

  const cachedSet = reactive<number[]>([])
  useGC($newsStore()?.news, cachedSet)

  let cachedCursor: string | undefined = undefined
  let prevParams = {}

  function switchMode() {
    searchMode.value = !searchMode.value

    if (searchMode.value) {
      prevParams = getBothModesCommonFilterParams()
      lockHead()
      cache()
      search()
    } else {
      const paramsChanged = !deepEqual(
        prevParams,
        getBothModesCommonFilterParams()
      )
      if (paramsChanged) {
        reload()
      } else {
        getCached()
        unlockHead()
      }
    }
  }

  function cache() {
    set.forEach(id => cachedSet.push(id))
    cachedCursor = params.cursor
  }

  function getCached() {
    set.length = 0
    cachedSet.forEach(id => set.push(id))
    cachedSet.length = 0
    params.cursor = cachedCursor
    cachedCursor = undefined
  }

  function search() {
    params.cursor = undefined
    set.length = 0
    newsList.value.length = 0
    loadNext()
  }

  function getBothModesCommonFilterParams() {
    return {
      from_dt: datesFilter.value?.start,
      to_dt: datesFilter.value?.end,
      hide_republications: hideRepublications.value,
      ...getFilterParams(),
    }
  }

  // -------------------------------------------------------------------------
  //            Складывание новостей в плашку "Есть новые новости N"
  // -------------------------------------------------------------------------
  const isLockedHead = ref<boolean>(false)
  const lockedHeadSet = reactive<number[]>([])
  useGC($newsStore()?.news, lockedHeadSet)
  const isFullHeadSet = ref(false)

  function pushLockedHeadSet(id: number) {
    if (isFullHeadSet.value) return

    lockedHeadSet.push(id)

    if (lockedHeadSet.length >= MAX_SIZE_HEAD_SET) {
      isFullHeadSet.value = true
    }
  }

  function unlockHead() {
    if (searchMode.value) return

    if (isFullHeadSet.value) {
      reload()
    } else {
      lockedHeadSet.forEach(id => unshiftSet(id))
    }

    lockedHeadSet.length = 0
    isLockedHead.value = false
  }

  function lockHead() {
    isLockedHead.value = true
  }

  return {
    error,
    set,
    newsList,
    hasMoreItems,
    isFetching,

    isLockedHead,
    lockedHeadSet,
    pushLockedHeadSet,
    unlockHead,
    lockHead,

    loadNext,
    reload,
    slice,
    unshiftSet,

    searchMode,
    showSearch,
    cachedSet,
    switchMode,
    updateShowSearch,
    search,

    $newsStore,
    $refStore,
    $userProfileStore,
    $groupsAndUsersStore,
    $codesStore,
    $localFiltersStore,
    $filterStore,
  }
}

export type PublishedNewsStore = ReturnType<typeof define>

const gatewaySubs = () => [
  {
    type: GatewaySubType.NEWS,
    onMessage: async (store: PublishedNewsStore, message: GatewaySubscriptionHubMessage) => {
      if (!message.payload.data) {
        return
      }
      const data = message.payload.data as NewsSubscriptionData

      const predicates = [
        checkNewsStatus.bind(null, store.$refStore, [
          "PUBLISHED",
          "REPUBLISHED",
          "REPUBLISHED_UNEDITED",
        ]),
        checkByHideRepublicationsFilter.bind(
          null,
          store.$refStore,
          store.$localFiltersStore
        ),
        checkByDatesFilter.bind(null, store.$localFiltersStore),
        checkByUsersFilter.bind(
          null,
          store.$filterStore,
          store.$groupsAndUsersStore
        ),
        checkByCodesFilter.bind(null, store.$filterStore, store.$codesStore),
        checkByFeedFilter.bind(null, store.$filterStore, store.$codesStore),
        checkByNewsEvent.bind(
          null,
          store.$refStore,
          ["LINKS_DELETED"],
          data.type_id
        ),
      ]
      /* Проверяем, что новость соответствует всем predicates */
      let checked = true
      for await (const func of predicates) {
        checked = await func(data.news)
        if (!checked) {
          break
        }
      }
      /* Если новость соответствует всем predicates и ее еще нет в массиве set,
       * то добавляем ее id в начало, сортировку по времени обеспечивает сокет,
       * т.е. новая новость всегда будет более свежая, чем ранее полученные */
      if (checked && !store.set.includes(data.news.id)) {
        /* Если скролл заблокирован, то складываем новости в отдельную очередь,
         * в противном случае складываем в основную очередь */
        if (store.isLockedHead) {
          store.pushLockedHeadSet(data.news.id)
        } else {
          store.unshiftSet(data.news.id, true)
        }
      }
    },
  },
] satisfies StoreGatewaySubscriptionIdentification[]

export default {
  define,
  gatewaySubs,
}
