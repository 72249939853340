import {
  defineStore,
  createPinia,
  type Pinia,
  type Store,
  type StateTree,
} from "pinia"
import type { GatewaySubType } from "@/ContextApp/services/gateway/constants"
import type { SubscriptionParams } from '@/ContextApp/services/gateway/types'
import type { GatewaySubscriptionHubMessage } from '@/ContextApp/middleware/gateway'


export type StoreGatewaySubscriptionIdentification =
  Omit<StoreGatewaySubscription, 'params'>
  & {
    params?: Omit<SubscriptionParams, "subscription_type">
  }

type PiniaDefineType = Parameters<typeof defineStore>[1]

// store here is arbitrary object, returned from define()
export type StoreGatewaySubscription = {
  type: GatewaySubType
  params?: SubscriptionParams
  onMessage: (store: PiniaDefineType, message: GatewaySubscriptionHubMessage) => void
}

export type StoreRecord = {
  store: Store
  unsubscribe: () => void
  gatewaySubs?: () => StoreGatewaySubscriptionIdentification[]
  onAction?: (context: any) => void
  onUpdate?: (...args: any[]) => void
}

export class AppStore {
  private _root: Pinia
  private _storeListeners: {
    [key: string]: {
      onCreated?: (store: StoreRecord) => any
      onDeleted?: (store: StoreRecord) => any
    }
  }
  public stores: Record<string, StoreRecord>

  public contexts: Record<string, Record<string, StoreRecord>>

  constructor() {
    this._root = createPinia()
    this.stores = {}
    this.contexts = {}

    this._storeListeners = {}
  }

  onStoreCreated(callbackId: string, callback: (storeRecord: StoreRecord) => any) {
    if (!this._storeListeners[callbackId]) {
      this._storeListeners[callbackId] = {}
    }
    this._storeListeners[callbackId].onCreated = callback
  }

  onStoreDeleted(callbackId: string, callback: (storeRecord: StoreRecord) => any) {
    if (!this._storeListeners[callbackId]) {
      this._storeListeners[callbackId] = {}
    }
    this._storeListeners[callbackId].onDeleted = callback
  }

  createStore({
    name,
    contextId,
    define,
    gatewaySubs,
    onUpdate,
  }: {
    name: string
    contextId: string | null
    define: (name?: string, contextId?: string | null) => any
    gatewaySubs?: StoreRecord["gatewaySubs"]
    onUpdate?: (updated: any, store: any) => void
  }): Store {
    if (this.stores[name]) {
      return this.stores[name].store
    } else {
      const makeStore = defineStore(name, () => {
        return {
          ...define(name, contextId),
        }
      })

      const store = makeStore(this._root)
      Object.values(store).forEach(prop => {
        try {
          if (prop && typeof prop === "object") {
            prop._$storeName = store.$id
          }
        } catch (err) {
          console.error("Error adding props to store:", err)
        }
      })
      const unsubscribe = store.$subscribe(() => {
        // @ts-expect-error обращаемся к внутреннему полю Pinia
        const updatedStore = this._root._s.get(store.$id)

        if (onUpdate) {
          onUpdate(null, updatedStore)
        }
      })

      this.stores[name] = {
        store,
        gatewaySubs,
        unsubscribe,
      }

      if (contextId) {
        if (!this.contexts[contextId]) {
          this.contexts[contextId] = {}
        }
        this.contexts[contextId][name] = { store, gatewaySubs, unsubscribe }
      }

      Object.values(this._storeListeners).forEach(({ onCreated }) => {
        if (onCreated) {
          onCreated(this.stores[name])
        }
      })
    }

    return this.stores[name].store
  }

  deleteStore(storeId: string, contextId?: string) {
    const storeRecord = this.stores[storeId]
    if (!storeRecord) return
    Object.values(this._storeListeners).forEach(({ onDeleted }) => {
      if (onDeleted) {
        onDeleted(this.stores[storeId])
      }
    })
    if (storeRecord.unsubscribe) {
      storeRecord.unsubscribe()
    }
    storeRecord.store.$dispose()
    delete this._root.state.value[storeId]
    delete this.stores[storeId]
    if (contextId) {
      delete this.contexts?.[contextId][storeId]
    }
  }

  getStore<name extends string, StoreT extends StateTree>(
    storeId: string,
    contextId?: string | null
  ): Store<name, StoreT> | null {
    if (contextId) {
      const contextStores = this.contexts[contextId]
      return contextStores
        ? (contextStores[`${storeId}--${contextId}`]?.store as Store<
            name,
            StoreT
          >)
        : null
    } else {
      return this.stores[storeId]?.store as Store<name, StoreT>
    }
  }
}

export default new AppStore()
