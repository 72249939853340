export const codeTypes = {
  iptc: ["900"],
  moex: ["905"],
  spb: ["910"],
  feed: ["950", "951"],
  publication: ["902"],
  publicationTechnical: ["9029"],
  periodical: ["907"],
} as const

export const codePaths = {
  allCodes: "000000000000000000", // "Все коды"
  iptc: "900000000000000000", // "IPTC"
  regions: "082000000000000000", // "Административно-территориальные образования"
  ipOfInterfax: "950000000000000000", // "Информационная продукция ЗАО "Интерфакс"
  publicationsType: "902000000000000000", // "Направления публикаций",
  technicalPublications: "902900000000000000", // "Технологические направления"
  periodical: "907000000000000000", // "Периодические издания"
} as const

type CodeIs = {
  iptc: (code: any) => boolean
  moex: (code: any) => boolean
  spb: (code: any) => boolean
  feed: (code: any) => boolean
  publication: (code: any) => boolean
  publicationTechnical: (code: any) => boolean
  periodical: (code: any) => boolean
}
export const codeIs: CodeIs = Object.entries(codeTypes).reduce(
  (acc, [code, prefixes]) => {
    acc[code] = (code: any) => prefixes.some(p => code?.path?.startsWith(p))
    return acc
  },
  {} as CodeIs
)

export const GroupsOrder = {
  "950000000000000000": 0, // Информационная продукция ЗАО "Интерфакс"
  "951000000000000000": 1, // Специальная информационная продукция
  "902000000000000000": 2, // Направления публикаций
  "900000000000000000": 3, // IPTC
  "925000000000000000": 4, // Макрогеографические регионы
  "901000000000000000": 5, // Страны мира и территории
  "082000000000000000": 6, // Административно-территориальные образования
  "905000000000000000": 7, // Коды РТС
  "910000000000000000": 8, // Коды СПБ
  "907000000000000000": 9, // Периодические издания
  "920000000000000000": 10, // Тип новости
} as const
