<script setup lang="ts">
import PsCheckbox from "@/ContextTab/components/UI/PsCheckbox.vue"
import PsIcon from "@/ContextTab/components/UI/PsIcon.vue"
import PsQuadbox from "@/ContextTab/components/UI/PsQuadbox.vue"
import { computed } from "vue"

const props = defineProps<{
  opened: boolean | number
  item: any
  filterState: any
  mode: "check" | "multi"
}>()

const emit = defineEmits(["change", "click"])

const itemName = computed(() => {
  if (props.item.isGroup) {
    return `${props.item.code} - ${props.item.name}`
  }

  const fullName = `${props.item.last_name || ""} ${
    props.item.first_name || ""
  } ${props.item.patronymic || ""}`

  return `${props.item.code}${
    fullName.replace(/\s/g, "") ? " - " + fullName : ""
  }`
})

const changeState = (id: string | number) => {
  const currentState = props.filterState[id]?.state
  switch (currentState) {
    case "unchecked":
      emit("change", "included")
      break
    case "included":
      emit("change", "excluded")
      break
    case "excluded":
      emit("change", "unchecked")
      break
    default:
      emit("change", "included")
  }
}
</script>

<template>
  <div
    class="tree-item"
    :class="{
      opened,
      disabled: item.disabled,
      nonopenable: !item?.children?.length && item?.track?.length <= 1,
    }"
    @click="emit('click', $event)"
  >
    <PsIcon
      v-if="item?.children?.length"
      name="tree-arrow"
      :size="11"
      class="tree-arrow"
    />
    <template v-if="!item.nonSelectable">
      <template v-if="mode === 'multi'">
        <PsQuadbox
          :state="filterState[item.id]?.state ?? 'unchecked'"
          @change="emit('change', $event)"
        />
      </template>
      <template v-else>
        <PsCheckbox
          :checked="filterState[item.id]?.state ?? 'unchecked'"
          checkedValue="included"
          uncheckedValue="unchecked"
          @update:checked="emit('change', $event)"
        />
      </template>
    </template>
    <span
      :class="filterState[item.id]?.state ?? 'unchecked'"
      @click="changeState(item.id)"
    >
      {{ itemName }}
    </span>
  </div>
</template>

<style scoped lang="postcss">
.tree-item {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  gap: 0.25rem;
  min-height: 24px;
  padding: 2px 0;
  vertical-align: middle;

  & span {
    display: inline-block;
  }
}
.opened {
  .tree-arrow {
    transform: rotate(90deg);
  }
}
.disabled {
  background-color: rgba(255, 67, 67, 0.2);
  opacity: 0.5;
}

.nonopenable {
  /* размер иконки tree-arrow + .tree-item gap */
  padding-left: calc(11px + 0.25rem);
}
</style>
