<script setup lang="ts">
import AuthorsTree from "./AuthorsTree.vue"
import PsIcon from "@/ContextTab/components/UI/PsIcon.vue"
import { computed, reactive, useTemplateRef, watch } from "vue"
import useGroupAndUsers from "@/ContextTab/modules/news/composables/useGroupAndUsers"
import type {
  AuthorFilterState,
  AuthorsItemsMap, FilterProp,
  IAuthorTreeItem
} from "@/ContextTab/modules/news/authors/types"
import { EQuadboxState } from "@/ContextTab/components/UI/types"
import { useFilterTreeUtils } from "@/ContextTab/modules/news/composables/useFilterTreeUtils"

const props = withDefaults(
  defineProps<{
    filters: FilterProp,
    showBlockedUsers?: boolean
  }>(),
  {
    showBlockedUsers: false,
  }
)

const emit = defineEmits<{
  (e: "update", payload: AuthorFilterState): void,
}>()

const {
  groupAndUsersById,
  groupAndUsersByUid,
  groupsAndNonBlockedUsersByUid,
} = useGroupAndUsers()

const tree = useTemplateRef('tree')

const itemsByUidMap = computed<AuthorsItemsMap>(() =>
  props.showBlockedUsers
    ? groupAndUsersByUid.value
    : groupsAndNonBlockedUsersByUid.value
)

const filterState = reactive<AuthorFilterState>({})

const {
  removePartiallySelected,
  recalcPartiallySelected,
} = useFilterTreeUtils(filterState, groupAndUsersById, groupAndUsersByUid)

watch(itemsByUidMap, (newItems) => {
    if (!newItems) {
      return
    }

    Object.entries<(number | string)[]>(props.filters)
      .forEach(([key, list]) => {
        list?.forEach((id: number | string) => {
          if (["user_group_ids", "user_group_excluded_ids"].includes(key)) {
            id = `${id}G`
          }
          const author = groupAndUsersById.value[id]
          if (["user_ids", "user_group_ids"].includes(key)) {
            includeItem(author, true)
          } else if (["user_excluded_ids", "user_group_excluded_ids"].includes(key)) {
            excludeItem(author, true)
          }
        })
      })
  },
  { immediate: true }
)

const selectedAuthors = computed<{ item: IAuthorTreeItem, state: EQuadboxState }[]>(() =>
  Object.values(filterState)
    .filter(
      ({ id, state }) =>
        groupAndUsersById.value &&
        groupAndUsersById.value[id] &&
        ([EQuadboxState.included, EQuadboxState.excluded].includes(state))
    )
    .map(({ id, state }) => ({
      item: groupAndUsersById.value[id],
      state,
    }))
)

function uncheckParent(itemUid: number | string) {
  if (itemUid === 0) {
    return
  }
  const itemId = itemsByUidMap.value[itemUid]?.id
  const record: any = filterState[itemId] ?? { id: itemId, state: EQuadboxState.partial }
  if (record.state === EQuadboxState.partial) {
    record.state = EQuadboxState.unchecked
  }
  filterState[itemId] = record
}


function emitUpdate(silent: boolean) {
  recalcPartiallySelected()

  if (!silent) {
    emit("update", removePartiallySelected(filterState))
  }
}

function includeParent(itemUid: number | string) {
  const itemId = itemsByUidMap.value[itemUid]?.id
  if (!itemId) {
    return
  }
  const record = filterState[itemId] ?? { id: itemId, state: EQuadboxState.partial }
  if (record.state === EQuadboxState.unchecked) {
    record.state = EQuadboxState.partial
  }
  filterState[itemId] = record
}

function includeItem(item: IAuthorTreeItem, silent = false) {
  if (!item) {
    return
  }
  const record = filterState[item.id] ?? { id: item.id, state: EQuadboxState.included }
  record.state = EQuadboxState.included
  filterState[item.id] = record
  item.track?.forEach(includeParent) // track here - uid[]

  emitUpdate(silent)
}

function excludeItem(item: IAuthorTreeItem, silent = false) {
  if (!item) {
    return
  }
  const record = filterState[item.id] ?? { id: item.id, state: EQuadboxState.excluded }
  record.state = EQuadboxState.excluded
  filterState[item.id] = record
  item.track?.forEach(includeParent) // track here - uid[]

  emitUpdate(silent)
}

function uncheckItem(item: IAuthorTreeItem, silent = false) {
  if (!item) {
    return
  }
  const record = filterState[item.id] ?? { id: item.id, state: EQuadboxState.unchecked }
  record.state = EQuadboxState.unchecked
  filterState[item.id] = record
  const author = groupAndUsersById.value[item.id]
  author.track?.forEach(uncheckParent) // track here - uid[]

  emitUpdate(silent)
}

function changeItemState(item: IAuthorTreeItem, state: string) {
  if (state === EQuadboxState.included) {
    includeItem(item)
  }
  if (state === EQuadboxState.excluded) {
    excludeItem(item)
  }
  if (state === EQuadboxState.unchecked) {
    uncheckItem(item)
  }
}
</script>

<template>
  <div class="wrapper">
    <div class="tree-wrapper">
      <AuthorsTree
        ref="tree"
        :items="itemsByUidMap"
        :filterState="filterState"
        :allOpened="false"
        mode="multi"
        @change="changeItemState"
      />
    </div>
    <div v-if="selectedAuthors?.length" class="authors-shelf">
      <span>Добавленные пользователи/группы:</span>
      <div class="authors-list">
        <div
          v-for="author of selectedAuthors"
          :key="author.item.id"
          :class="author.state"
        >
          <span>{{ author.item.name }}</span>
          <PsIcon
            type="font"
            name="close"
            size="14"
            class="remove-code"
            @click="uncheckItem(author.item)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.tree-wrapper {
  overflow: hidden;
  flex: 1;
}

.authors-shelf {
  max-height: 111px;
  overflow: hidden;
  padding-top: 1rem;
  width: 100%;
  font-size: 0.75rem;
  display: flex;
  margin-bottom: 1rem;
  flex-direction: column;

  & > span {
    display: block;
    margin-bottom: 10px;
  }

  .authors-list {
    display: flex;
    max-height: 100%;
    overflow: auto;
    gap: 6px;
    width: 100%;
    flex-flow: wrap;

    & > div {
      align-items: center;
      display: flex;
      border-radius: 2px;
      padding: 0 3px 0 6px;
    }
    .included {
      border: 1px solid #9dbccd;
      background: #d9ebfb;
    }
    .excluded {
      border: 1px solid #cd9d9d;
      background: #fbd9d9;
    }

    .remove-code {
      cursor: pointer;
      margin-top: 1px;
    }
  }
}
</style>
