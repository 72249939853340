import { createApiClient, ApiEvents } from "ps-client"
import { API_ROOT, VERSION } from "@/config"
import { sessionId } from "@/ContextApp/session"

const tokenFns = {
  getAccessToken: async () => "",
  getRefreshToken: async () => "",
  updateToken: async (arg?: any) => null,
}

const api = createApiClient({
  url: API_ROOT,
  version: VERSION,
  getSessionId: () => Promise.resolve(sessionId),
  getAccessToken: async () => tokenFns.getAccessToken(),
  getRefreshToken: async () => tokenFns.getRefreshToken(),
  timeout: 120000,
  globalHeaders: {
    "Cache-Control": "no-store",
    "X-Correlation-ID": `${sessionId}--APP`,
  },
})

export function initApi({
  getAccessToken,
  getRefreshToken,
  updateToken,
}: typeof tokenFns) {
  tokenFns.getAccessToken = getAccessToken
  tokenFns.getRefreshToken = getRefreshToken

  api.on(ApiEvents.tokenRefreshed, res => updateToken(res?.body?.data))

  api.on(ApiEvents.networkError, res => {
    console.error("NETWORK ERROR", res)
  })
}

export default api
