<script setup lang="ts">
import type { PsApiV2 } from "ps-client"
import Horizontal from "./panelGrid/Horizontal.vue"
import Vertical from "./panelGrid/Vertical.vue"
import { computed } from "vue"

const props = defineProps<{
  gridType: PsApiV2.PublishedPanelMode,
  isFluidLayout: boolean,
}>()

const gridComponent = computed(() =>
  props.gridType === 1 ? Vertical : Horizontal
)
</script>

<template>
  <component
    :is="gridComponent"
    :isFluidLayout="isFluidLayout"
  >
    <template #left>
      <slot name="left" />
    </template>
    <template #main>
      <slot name="main" />
    </template>
    <template #preview>
      <slot name="preview" />
    </template>
  </component>
</template>
